import KitchenConfig from "./products/kitchenaid";

// Use this form if there are no custom elements - empty div
import Null from "./products/null";

const ProductList = {
  1: {
    type: "3D",
    imageURL:
      "https://solutions-engineering.s3.amazonaws.com/quickdemo/thom/thom.png",
    threekit: "db78ac06-0627-414f-a4f2-297dd22ef3d1",
    name: "Five Stone Ring",
    scene: "4fd59257-53cd-4e8e-9e5e-194c763341f8",
    initialConfig: {},
    showAR: false,
    showConfig: true,
    thumb: true,
    cache: {
      maxAge: 86400,
      scope: "1-20-2021",
    },
    brand: "Jewelry",
    form: Null,
  },
  2: {
    type: "3D",
    imageURL:
      "https://preview.threekit.com/api/files/187420c4-06ab-4a07-ba31-05af4037eb36/content",
    threekit: "b6894bb3-8dc8-4e91-94b5-c5e758605ddd",
    name: "Hero Stone Ring",
    scene: "",
    initialConfig: {},
    showAR: false,
    showConfig: true,
    thumb: true,
    cache: {
      maxAge: 86400,
      scope: "4-22-2021",
    },
    brand: "Jewelry",
    form: Null,
  },
};
export { ProductList };

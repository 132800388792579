import React from "react";
import "./Product.css";
import "antd/dist/antd.css";
import { PageHeader } from "antd";
import { ProductList } from "../config/Configs.js";
import ReactGA from "react-ga";

let clicked = false;

const hideHand = () => {
  document.getElementById("hand-indicator") == undefined
    ? window.location.reload()
    : (document.getElementById("hand-indicator").style = "display: none");
};

class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      img: "",
      loaded: false,
    };
  }
  componentDidMount() {
    const productId = this.props.match.params.productId;
    const product = ProductList[productId];

    ReactGA.initialize("UA-63905846-9");
    ReactGA.set({ dimension1: "Jewelry Quickdemo Site" });
    ReactGA.pageview("Jewelry Quickdemo " + product.name);

    if (clicked == false) {
      document
        .getElementById("threekit-container")
        .addEventListener("mousedown", function () {
          // document.getElementById("hand-container").remove();
          hideHand();
        });

      document
        .getElementById("threekit-container")
        .addEventListener("touchstart", function () {
          // document.getElementById("hand-container").remove();
          hideHand();
        });
    }

    !product
      ? console.log("no product")
      : // Put player here
        window
          .threekitPlayer({
            authToken:
              product.name == "Five Stone Ring"
                ? "c6596a9a-638d-4d0d-889b-885dec899ab7"
                : "f5f9f655-e57d-48a2-b1a0-cc6bbb8012ed",
            el: document.getElementById("player"),
            assetId: `${product.threekit}`,
            stageId: `${product.scene}`,
            initialConfiguration: product.initialConfig,
            showAR: product.showAR,
            cache: product.cache,
            showLoadingThumbnail: product.thumb,
            showConfigurator: product.showConfig,
          })
          .then(async (api) => {
            window.player = api;
            window.configurator = await api.getConfigurator();

            api.on(window.player.scene.PHASES.RENDERED, () =>
              this.setState({ loaded: true })
            );
          });
  }
  render() {
    const productId = this.props.match.params.productId;
    const product = ProductList[productId];

    return (
      <div>
        {!product ? (
          <div>
            Invalid product URL. Please navigate <a href="/">home</a>
          </div>
        ) : (
          <div>
            <PageHeader
              style={{
                border: "1px solid rgb(235, 237, 240)",
              }}
              onBack={() => window.location.assign("/")}
              title={product.name}
            />

            <div id="threekit-container">
              {this.state.loaded ? (
                <div className="stage" id="hand-container">
                  <div id="hand-indicator" className="hand bounce-2">
                    <img
                      style={{ height: "30px", width: "30px" }}
                      src="https://solutions-engineering.s3.amazonaws.com/media/web-assets/hand.png"
                    />
                  </div>
                </div>
              ) : (
                <p></p>
              )}

              <div
                id="player"
                style={{
                  height: "50vh",
                  width: "100%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  background: "#ffffff",
                }}
              ></div>
              <product.form />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Product;
